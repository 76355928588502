import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import { NewEvent } from '../models/new-event';
import { NewItem } from '../models/new-item';
import { AuthService } from '../services/auth.service';
import { ItemsService } from '../services/items.service';
import { OffersService } from '../services/offers.service';

@Component({
  selector: 'app-item-page',
  templateUrl: './item-page.component.html',
  styleUrls: ['./item-page.component.css']
})
export class ItemPageComponent implements OnInit {

  itemId : string | undefined;
  itemData : NewItem | undefined;
  photos :  string[] | undefined;
  removeDialog : boolean = false;
  ownItem: boolean = false;
  addEvent: boolean = false;
  newEvent: NewEvent;
  date = new FormControl(moment());

  buttonColor : string = "black";
  buttonType : string = "buy";

  showOfferInput : boolean = false;
  offerAmount : number = 0;

  constructor(private route: ActivatedRoute, private itemsService: ItemsService, private authService: AuthService, private offersService: OffersService) {
    this.newEvent = new NewEvent;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.itemId = params.id;

      this.itemsService.getItem(this.itemId!).then(response => {
        this.itemData = <NewItem>response;
        this.itemData.id = this.itemId;

        this.authService.user.subscribe((user) => {
          if (user && this.itemData) {
            this.ownItem = this.itemData.user_id === user.uid;
          }
        });

        this.itemsService.getEvents(this.itemId!).then(response => {
          this.itemData!.events = response;
        });
        
        this.itemsService.getImages(this.itemId!).subscribe((response) => {
          let parsedResponse: string[] = <string[]>response;
          this.photos = parsedResponse;
        });
      });
    });
  }

  public async submitOffer() {
    const price = this.offerAmount;
  
    try {
      const offerId = await this.offersService.makeOffer(this.itemData!, price);
      console.log(`Offer with ID: ${offerId} was made successfully`);
    } catch (error) {
      console.error(`Error making offer: ${error}`);
    }
  }

  public cancelOffer() {
    this.showOfferInput = false;
  }

  public removeItem() {
    this.removeDialog = false;
    if (this.itemData) {
      this.itemsService.removeItem(this.itemData).then(function(){
        var url = "/items/loved";
        window.location.href = url;
      })
    }
  }

  public toggleDialog() {
    this.removeDialog = !this.removeDialog;
  }

  public toggleAddEvent() {
    this.addEvent = !this.addEvent;
  }

  public registerEvent () {
    const d = moment(this.date.value);
    this.newEvent.date = Timestamp.fromDate(d.toDate());

    if(this.itemId) {
      this.itemsService.createEvent(this.itemId, this.newEvent).then((response) => {
        this.itemData!.events.push(response)
      });
    }
    
  } 

  public removeEvent (eventId: string | undefined) {
    if (eventId && this.itemData) {
      this.itemsService.removeEvent(this.itemData, eventId).then((response) => {
        for (let event of this.itemData!.events) {
          if (event.id === eventId) {
            const index = this.itemData!.events.indexOf(event, 0);
            if (index > -1) {
              this.itemData!.events.splice(index, 1);
            }
            break
          }
        }
      });
    }
  }

}
