import { Timestamp } from "firebase/firestore";

export class NewEvent {
    public id : string | undefined;
    public likes: number = 0;
    public type: string = '';
    public description: string = '';
    public date: Timestamp | undefined;

    constructor() {}
  }
