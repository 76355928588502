import { Injectable } from '@angular/core';
import { Offer } from '../models/offer';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0
  };

  tokenizationSpecification = {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      'gateway': 'example',
      'gatewayMerchantId': 'exampleGatewayMerchantId'
    }
  };

  allowedCardNetworks = ["MASTERCARD", "VISA"];

  allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];

  baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: this.allowedCardAuthMethods,
      allowedCardNetworks: this.allowedCardNetworks
    }
  };

  cardPaymentMethod = Object.assign(
    {tokenizationSpecification: this.tokenizationSpecification},
    this.baseCardPaymentMethod
  );

  constructor() { }

  public createPaymentRequest (offer: Offer)  : google.payments.api.PaymentDataRequest {

    const paymentRequest: google.payments.api.PaymentDataRequest = {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: "CARD",
          parameters: {
            allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
            allowedCardNetworks: ["VISA", "MASTERCARD"]
          },
          tokenizationSpecification: {
            type: "PAYMENT_GATEWAY",
            parameters: {
              gateway: "example",
              gatewayMerchantId: "exampleGatewayMerchantId"
            }
          }
        }
      ],
      merchantInfo: {
        merchantId: "BCR2DN4T6TNIJIDA",
        merchantName: "Airgy LTD"
      },
      transactionInfo: {
        totalPriceStatus: "FINAL",
        totalPriceLabel: "Total",
        totalPrice: offer.offerAmount.toString(),
        currencyCode: "USD",
        countryCode: "US"
      }
    };

    return paymentRequest;
  }
}
