import { Component, Input, OnInit } from '@angular/core';
import { NewItem } from '../models/new-item';
import { ItemsService } from '../services/items.service';

@Component({
  selector: 'app-simple-card',
  templateUrl: './simple-card.component.html',
  styleUrls: ['./simple-card.component.css']
})
export class SimpleCardComponent implements OnInit {
  @Input() item: NewItem | undefined; 
  photo : string | undefined;
  showPrice: boolean | undefined;
  
  constructor(private itemsService: ItemsService) { 
  }

  ngOnInit() {
    if (this.item && this.item.id && this.item.price != undefined) {
      this.itemsService.getImages(this.item.id)
      .subscribe(response => {
        let parsedResponse: string[] = <string[]>response;
        this.photo = parsedResponse[0];
      });
      this.showPrice = (this.item.price > 0);
    }
  }
}
