import { Injectable } from '@angular/core';
import { User } from 'firebase/auth';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private api : string = environment.apiURL + "/api/v1/user";

  constructor(private httpClient: HttpClient) { }

  createUser (user: User) {
    var formData: any = new FormData();
    formData.append("uid", user.uid);
    formData.append("displayName", user.displayName);
    formData.append("email", user.email);
    formData.append("phoneNumber", user.phoneNumber);
    formData.append("photoURL", user.photoURL);

    return this.httpClient.post(this.api, formData);
  }
}
