<div class="container">
    <div class="row flex-center">
        <div class="col-sm-9 col-md-10 mb-3 mb-md-0 h-100">
            <div class="card card-span text-white h-100 elevated-card" style="background: #ffffff73;">
                <div class="main-image-container" style="background-image: url('{{photos![0]}}');">
                </div>
                <div class="card-body px-xl-5 px-md-3 pt-0 pb-7">
                    <div class="d-flex justify-content-between align-items-center bg-100 mt-n5 me-auto">
                        <img src="{{photos![0]}}" width="60" alt="..." />
                        <div class="d-flex flex-1 justify-content-around">
                            <span class="text-900 text-center">
                                <b><i data-feather="eye">Category </i></b><br>
                                <span class="text-900 ms-2">{{ itemData!.category }}</span>
                            </span>
                            <span class="text-900 text-center">
                                <b><i data-feather="eye">Price </i></b><br>
                                <span class="text-900 ms-2">${{ itemData!.price }}</span></span>
                            <span class="text-900 text-center">
                                <b><i data-feather="eye">Materials </i></b><br>
                                <span class="text-900 ms-2">{{ itemData!.material }}</span>
                            </span>
                        </div>
                    </div>
                    <h5 class="text-900 mt-3">
                        {{ itemData!.title }}
                        <br>
                        <span class="fw-normal">
                            {{ itemData!.subtitle }}
                        </span>
                    </h5>
                    <div class="offer-container" *ngIf="showOfferInput">
                        <mat-form-field appearance="legacy" style="width: 50%;">
                            <input type="number" matInput placeholder="Enter offer amount" [(ngModel)]="offerAmount">
                        </mat-form-field>
                        
                        <button mat-button color="primary" (click)="submitOffer()">Submit</button>
                        <button mat-button color="primary" (click)="cancelOffer()">Cancel</button>
                    </div>
                    <button mat-button color="primary" *ngIf="!ownItem && itemData!.selling && !showOfferInput" (click)="showOfferInput = true">Make an Offer</button>
                         
                    <p class="text-900 mt-3">Description: {{ itemData!.description }}</p>
                    <p class="text-900 mt-3">Condition: {{ itemData!.condition }}</p>
                    <p>
                        <span appearance="fill" class="row h-100 g-2 scroll-max">
                            <div *ngFor="let image of photos" class="thumbnail-container col-sm-9 col-md-4 mb-3 mb-md-0 h-100">
                                <img [src]="image" class="thumbnail">
                            </div>
                        </span>
                    </p>

                    <div  *ngFor="let event of itemData!.events" class="elevated-card new-event-bubble">
                        <span *ngIf="event.type == 'story'" class="material-symbols-outlined new-event-icon">
                            history_edu
                        </span>
                        <span *ngIf="event.type == 'newoner'" class="material-symbols-outlined new-event-icon">
                            person_pin
                        </span>
                        <span *ngIf="event.type == 'repair'" class="material-symbols-outlined new-event-icon">
                            tools_wrench
                        </span>
                        <span *ngIf="event.type == 'damage'" class="material-symbols-outlined new-event-icon">
                            heart_broken
                        </span>
                        <div class="event-date">
                            {{ event.date!.toDate().toDateString() }}
                        </div>  
                        <div class="event-description">
                            {{ event.description }}
                        </div> 
                        <button *ngIf="ownItem" mat-stroked-button class="new-row-element" mat-stroked-button color="primary" (click)="removeEvent(event.id)">Delete</button>                     
                    </div>
                    
                    <div *ngIf="ownItem">
                        <div class="">
                            <p>
                                <span (click)="toggleAddEvent()" class="material-symbols-outlined add-timeline">
                                    add_circle
                                </span>
                            </p>
                            <div *ngIf="addEvent" class="elevated-card new-event-bubble">
                                <p>
                                    <mat-form-field appearance="legacy">
                                        <mat-label>Date</mat-label>
                                        <mat-hint>MM/DD/YYYY</mat-hint>
                                        <input autocomplete="off" style="color: black;" matInput [matDatepicker]="dp" [formControl]="date">
                                        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                                        <mat-datepicker #dp startView="multi-year" panelClass="example-month-picker">
                                        </mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field appearance="legacy">
                                        <mat-label>Event type</mat-label>
                                        <mat-select [(ngModel)]="newEvent!.type">
                                            <span class="material-symbols-outlined new-event-icon">
                                                history_edu
                                            </span>
                                            <mat-option value="story">
                                                Story
                                            </mat-option>
                                            <span class="material-symbols-outlined new-event-icon">
                                                person_pin
                                            </span>
                                            <mat-option value="newoner">
                                                New owner
                                            </mat-option>
                                            <span class="material-symbols-outlined new-event-icon">
                                                tools_wrench
                                            </span>
                                            <mat-option value="repair">
                                                Repair
                                            </mat-option>
                                            <span class="material-symbols-outlined new-event-icon">
                                                heart_broken
                                            </span>
                                            <mat-option value="damage">
                                                Damage
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </p>
                                <p>
                                    <mat-form-field appearance="legacy" style="width: 100%;">
                                        <mat-label>Story details</mat-label>
                                        <textarea matInput [(ngModel)]="newEvent!.description" name="description" cdkTextareaAutosize
                                            #historyAutosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10"
                                            required style="color: black;"></textarea>
                                    </mat-form-field>
                                </p>
                                <button mat-stroked-button (click)="registerEvent()" color="primary">Add</button>
                            </div>

                        </div>
                        <button *ngIf="ownItem" mat-stroked-button class="new-row-element" mat-stroked-button color="primary" (click)="toggleDialog()">Delete</button>
                        <div class="new-row-element" *ngIf="removeDialog">
                            <p class="text-900 mt-3">Are you sure? this will remove the whole story, this can not be undone.</p>
                            <button mat-button color="primary" (click)="removeItem()">Delete</button>
                            <button mat-stroked-button color="primary" (click)="toggleDialog()">Cancel</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>
