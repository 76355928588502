export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyC-PWcUCqCIuUIIEBFtx19v7a_dZpYwkYU",
    authDomain: "artiquary.firebaseapp.com",
    projectId: "artiquary",
    storageBucket: "artiquary.appspot.com",
    messagingSenderId: "1078243548815",
    appId: "1:1078243548815:web:4ac60e6d4f7985f7f41afa",
    measurementId: "G-SHWNZ4GXE5"
  },
  apiURL: 'https://antiqry.com'
};
