import { DOCUMENT } from '@angular/common';
import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input, Renderer2, Inject  } from '@angular/core';
declare const google: {
  maps: {
    places: {
      Autocomplete: new (arg0: any, arg1: {
        types: string[]; // 'establishment' / 'address' / 'geocode'
      }) => any;
    }; event: { addListener: (arg0: any, arg1: string, arg2: () => void) => void; };
  };
};

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css']
})
export class AutocompleteComponent implements OnInit {
  @Input() adressType: string | undefined;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;

  autocompleteInput: string = '';
  queryWait: boolean = false;

  constructor(@Inject(DOCUMENT) private document: Document, private renderer2: Renderer2) {
  }

  ngOnInit() {
    this.loadAutoComplete();
  }

  private loadAutoComplete() {
    const url = 'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyCdJzHrA72LJMJnmLDHuolz8pxiSih6A_s';
    this.loadScript(url).then(() => this.getPlaceAutocomplete());
  }

  private loadScript(url: string) {
    return new Promise((resolve, reject) => {
      const script = this.renderer2.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.text = ``;
      script.async = true;
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;
      this.renderer2.appendChild(this.document.head, script);
    })
  }

  private getPlaceAutocomplete() {
      const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
          {
              types: [this.adressType!]  // 'establishment' / 'address' / 'geocode'
          });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
          const place = autocomplete.getPlace();
          this.invokeEvent(place);
      });
  }

  invokeEvent(place: Object) {
      this.setAddress.emit(place);
  }

}
