import { Component, OnInit } from '@angular/core';
import { OffersService } from 'src/app/services/offers.service';
import { Offer } from 'src/app/models/offer';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { User } from 'firebase/auth';
import { AuthService } from '../services/auth.service';
import { ItemsService } from '../services/items.service';
import { PaymentsService } from '../services/payments.service';

@Component({
  selector: 'app-offers-page',
  templateUrl: './offers-page.component.html',
  styleUrls: ['./offers-page.component.css']
})
export class OffersPageComponent implements OnInit {
  offers: Offer[] = [];
  filteredOffers: Offer[] = [];
  displayedColumns: string[] = ['thumbnail', 'itemName', 'action', 'created', 'emitterName', 'price', 'state', 'actions'];
  dataSource = new MatTableDataSource<Offer>();
  filters = [{value: 'all', viewValue: 'All'}, {value: 'buy', viewValue: 'Buy'}, {value: 'sell', viewValue: 'Sell'}];
  selectedFilter = 'all';
  user: User | undefined;
  paymentRequest = {};
  
  constructor(private offersService: OffersService, private authService: AuthService, private itemsService: ItemsService, private paymentsService: PaymentsService) { 
    this.user = authService.userData;
  }

  ngOnInit() {
    this.offersService.getMyOffers().then((offers) => {
      this.offers = offers;
      this.dataSource.data = this.offers;
      this.getThumbnails();
      this.getPaymentRequest();
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  sortData(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  public async getThumbnails() {
    this.offers.forEach((offer) => {
      this.itemsService.getImages(offer.itemId!)
      .subscribe(response => {
        let parsedResponse: string[] = <string[]>response;
        offer.thumbnailUrl = parsedResponse[0];
      });
      // doc.data() is never undefined for query doc snapshots
    });
  }

  public getPaymentRequest(){
    this.offers.forEach((offer) => {
      if (offer.state == 'accepted') {
        offer.paymentRequest = this.paymentsService.createPaymentRequest(offer);
      }
    });
  }

  acceptOffer(offer: Offer) {
    // Perform logic to accept the offer here
  }

  rejectOffer(offer: Offer) {
    // Perform logic to accept the offer here
  }

  cancelOffer(offer: Offer) {
    // Perform logic to accept the offer here
  }

  public onLoadPaymentData(event: Event) {
    console.log("load payment data", event);
  }
}