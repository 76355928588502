import { Component, OnInit, Input } from '@angular/core';
import { Offer } from '../models/offer';
import { AuthService } from '../services/auth.service';
import { ItemsService } from '../services/items.service';
import { OffersService } from '../services/offers.service';
import { PaymentsService } from '../services/payments.service';

@Component({
  selector: 'app-offer-card',
  templateUrl: './offer-card.component.html',
  styleUrls: ['./offer-card.component.css']
})
export class OfferCardComponent implements OnInit {
  @Input() offer: Offer | undefined;
  user: import("@angular/fire/auth").User | undefined;

  constructor(private offersService: OffersService, private authService: AuthService, private itemsService: ItemsService, private paymentsService: PaymentsService) { 
    this.user = authService.userData;
    if (this.offer == undefined) {
      this.offer = new Offer();
    }
    this.getThumbnails();
    this.getPaymentRequest();
  }

  ngOnInit(): void {  }

  acceptOffer(offer: Offer | undefined) {
    // Perform logic to accept the offer here
  }

  rejectOffer(offer: Offer | undefined) {
    // Perform logic to accept the offer here
  }

  cancelOffer(offer: Offer | undefined) {
    // Perform logic to accept the offer here
  }

  public onLoadPaymentData(event: Event) {
    console.log("load payment data", event);
  }

  public async getThumbnails() {
      this.itemsService.getImages(this.offer!.itemId!)
      .subscribe(response => {
        let parsedResponse: string[] = <string[]>response;
        this.offer!.thumbnailUrl = parsedResponse[0];
      });
  }

  public getPaymentRequest(){
      if (this.offer!.state == 'accepted') {
        this.offer!.paymentRequest = this.paymentsService.createPaymentRequest(this.offer!);
      }
  }

}
