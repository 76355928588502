import { Timestamp } from 'firebase/firestore';

export class Offer {
  public id: string | undefined;
  public itemId: string | undefined = undefined;
  public price: number = 0;
  public offerAmount: number = 0;
  public created: Timestamp | undefined = undefined;
  public state: string = 'pending';
  public emitterId: string | undefined = undefined;
  public emitterName: string | undefined = undefined;
  public receiverId: string | undefined = undefined;
  public receiverName: string = "";
  public thumbnailUrl: string | undefined = undefined;
  public itemName: string | undefined = undefined;
  public paymentRequest!: google.payments.api.PaymentDataRequest;

  constructor() {}
}
