import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import {ThemePalette} from '@angular/material/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { NgZone } from '@angular/core';
import {take} from 'rxjs/operators';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';

import { UsersService } from '../services/users.service';
import { ItemsService } from '../services/items.service';
import { NewItemResponse } from '../models/new-item-response';
import { NewItem } from '../models/new-item';
import { MatButton } from '@angular/material/button';
import { Timestamp } from 'firebase/firestore';
const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ListItemComponent implements OnInit {
  categories: string[] = [
    'Antiques',
    'Art',
    'Baby',
    'Books, Comics & Magazines',
    'Business, Office & Industrial',
    'Cameras & Photography',
    'Cars, Motorcycles & Vehicles',
    'Clothes, Shoes & Accessories',
    'Coins',
    'Collectables',
    'Computers/Tablets & Networking',
    'Crafts',
    'Dolls & Bears',
    'Events Tickets',
    'Everything Else',
    'Films & TV',
    'Garden & Patio',
    'Health & Beauty',
    'Holidays & Travel',
    'Home, Furniture & DIY',
    'Jewellery & Watches',
    'Mobile Phones & Communication',
    'Music',
    'Musical Instruments & DJ Equipment',
    'Pet Supplies',
    'Pottery, Ceramics & Glass',
    'Property',
    'Sound & Vision',
    'Sporting Goods',
    'Sports Memorabilia',
    'Stamps',
    'Toys & Games',
    'Vehicle Parts & Accessories',
    'Video Games & Consoles',
    'Wholesale & Job Lots',
  ];

  allMaterials: string[] = [
    '100% Cashmere',
    '100% Cotton',
    '100% Linen',
    '100% Polyester',
    '100% Silk',
    '100 % Solution Dyed Fibre',
    '100% V-Tron Synthetic Fibre',
    '100% Wool',
    'Abaca',
    'Acetate',
    'Acrylic',
    'Alder',
    'Alfa',
    'Alginate',
    'Alpaca',
    'Aluminium',
    'Angora',
    'Angora Rabbit Wool',
    'Animal Hair',
    'Aramid',
    'Ash',
    'Bamboo',
    'Bearskin',
    'Beaver',
    'Beech',
    'Birch',
    'Broom',
    'Buckskin',
    'Camel',
    'Canvas',
    'Cashgora',
    'Cashmere Blend',
    'Cast Iron',
    'Ceramic',
    'Chenille',
    'Cherry',
    'Chestnut',
    'Chiffon',
    'Coir',
    'Corduroy',
    'Cork',
    'Cotton',
    'Cotton Blend',
    'Cotton Sateen',
    'Cow Hide',
    'Crystal',
    'Curpro',
    'Damask',
    'Deer Hide',
    'Denim',
    'Elastodiene',
    'Elastolefin',
    'Elastomultiester',
    'Enamel',
    'EVA Foam',
    'Fabric',
    'Faux Fur',
    'Faux Hide',
    'Faux Leather',
    'Faux Silk',
    'Faux Suede',
    'Felt',
    'Flannel',
    'Flax',
    'Fleece',
    'Fluorofibre',
    'Foam',
    'Fur',
    'Goatskin',
    'Guanaco',
    'Hemp',
    'Henequen',
    'Iron',
    'Jacquard',
    'Jute',
    'Kapok',
    'Lace',
    'Lambskin',
    'Leather',
    'Linen Blend',
    'Llama',
    'Lyocell',
    'Maguey',
    'Mahogany',
    'Manila Hemp',
    'Maple',
    'Matelassé',
    'MDF/Chipboard',
    'Melamine',
    'Mesh',
    'Microfibre',
    'Modacrylic',
    'Modal',
    'Mohair',
    'Natural & Synthetic Fibre Blend',
    'Natural Fibre Blend',
    'Natural Fibres',
    'Net',
    'Nylon',
    'Oak',
    'Oilcloth',
    'Olefin',
    'Organic Cotton',
    'Otter',
    'Paper Fibre',
    'Pine',
    'Plastic',
    'Plush',
    'Polyamide',
    'Polyamide Fibre',
    'Polycarbamide',
    'Polycarbonate',
    'Polycotton',
    'Polyester',
    'Polyethylene',
    'Polyimide',
    'Polylactide',
    'Polypropylene/Olefin',
    'Polyurethane',
    'Protein',
    'PU',
    'PVC',
    'Rabbit Fur',
    'Ramie',
    'Rattan',
    'Recycled Fibre',
    'Reindeer Skin',
    'Resin',
    'Rosewood',
    'Rubber',
    'Rubberwood',
    'Satin',
    'Seagrass',
    'Sheepskin',
    'Silicone',
    'Silk',
    'Silk Blend',
    'Sisal/Seagrass',
    'Solid Wood',
    'Spandex',
    'Springbok Hide',
    'Stainless Steel',
    'Steel',
    'Stone',
    'Straw',
    'Suede',
    'Sunn',
    'Synthetic Fibre',
    'Synthetic Fibre',
    'Blend',
    'Teak',
    'Tiacetate',
    'Tibet Lambskin',
    'Triexta',
    'Trivinyl',
    'Velvet',
    'Vicuna',
    'Vinyl',
    'Vinylal',
    'Viscose',
    'Walnut',
    'Wicker',
    'Wood',
    'Wood Veneer',
    'Wool',
    'Wool Blend',
    'Yak Wool',
    'Yew',
    'Zebra Hide',
  ];

  countries = [
    'Afghanistan',
    'Åland Islands',
    'Albania',
    'Algeria',
    'American Samoa',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antarctica',
    'Antigua & Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia',
    'Bosnia & Herzegovina',
    'Botswana',
    'Bouvet Island',
    'Brazil',
    'British Indian Ocean Territory',
    'British Virgin Islands',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cape Verde',
    'Caribbean Netherlands',
    'Cayman Islands',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'Christmas Island',
    'Cocos',
    'Colombia',
    'Comoros',
    'Congo - Brazzaville',
    'Congo - Kinshasa',
    'Cook Islands',
    'Costa Rica',
    'Côte d’Ivoire',
    'Croatia',
    'Cuba',
    'Curaçao',
    'Cyprus',
    'Czechia',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Eswatini',
    'Ethiopia',
    'Falkland Islands',
    'Faroe Islands',
    'Fiji',
    'Finland',
    'France',
    'French Guiana',
    'French Polynesia',
    'French Southern Territories',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Heard & McDonald Islands',
    'Honduras',
    'Hong Kong SAR China',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Isle of Man',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jersey',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macao SAR China',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Martinique',
    'Mauritania',
    'Mauritius',
    'Mayotte',
    'Mexico',
    'Micronesia',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Niue',
    'Norfolk Island',
    'North Korea',
    'North Macedonia',
    'Northern Mariana Islands',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestinian Territories',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Pitcairn Islands',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Réunion',
    'Romania',
    'Russia',
    'Rwanda',
    'Samoa',
    'San Marino',
    'São Tomé & Príncipe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Sint Maarten',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Georgia & South Sandwich Islands',
    'South Korea',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'St. Barthélemy',
    'St. Helena',
    'St. Kitts & Nevis',
    'St. Lucia',
    'St. Martin',
    'St. Pierre & Miquelon',
    'St. Vincent & Grenadines',
    'Sudan',
    'Suriname',
    'Svalbard & Jan Mayen',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Timor-Leste',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad & Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks & Caicos Islands',
    'Tuvalu',
    'U.S. Outlying Islands',
    'U.S. Virgin Islands',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City',
    'Venezuela',
    'Vietnam',
    'Wallis & Futuna',
    'Western Sahara',
    'Yemen',
    'Zambia',
    'Zimbabwe',
  ];

  materials: string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  matCtrl = new FormControl('');
  filteredMaterial: Observable<string[]>;

  color: ThemePalette = 'primary';
  sell: boolean = false;
  visible: boolean = false;
  addOnBlur = true;

  date = new FormControl(moment());

  newItem: NewItem;

  subcategories: string[] = [];

  @ViewChild('materialInput')
  materialInput!: ElementRef<HTMLInputElement>;

  @ViewChild('listButton') listButton!: MatButton;
  listing : boolean = false;
  
  @ViewChild('historyAutosize')
  historyAutosize!: CdkTextareaAutosize;
  formattedAddress: any;
  address: any;

  uploadedImage: File | undefined;
  dbImage: any;
  postResponse: any;
  successResponse: string | undefined;
  image: any;
  imgFiles : string[] = [];

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable
      .pipe(take(1))
      .subscribe(() => this.historyAutosize.resizeToFitContent(true));
  }

  constructor(private _ngZone: NgZone, private itemService: ItemsService) {
    this.filteredMaterial = this.matCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit ? this._filter(fruit) : this.allMaterials.slice()
      )
    );

    this.newItem = new NewItem(
      '',
      '',
      false,
      null,
      false,
      '',
      '',
      '',
      '',
      '',
      '',
      undefined,
      [],
      '',
      '',
      '',
      '',
      []
    );
  }

  ngOnInit(): void {}

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.materials.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.matCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.materials.indexOf(fruit);

    if (index >= 0) {
      this.materials.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.materials.push(event.option.viewValue);
    this.materialInput.nativeElement.value = '';
    this.matCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allMaterials.filter((material) =>
      material.toLowerCase().includes(filterValue)
    );
  }

  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }
  
  public onImageUpload(e: any) {
   
    if(e.target.files && e.target.files.length) {
      for (const img of e.target.files) {
        let reader = new FileReader();

        reader.readAsDataURL(img);
    
        reader.onload = () => {
          this.imgFiles?.push(reader.result as string);  
        };
      }
    }
  }

  getAddrComponent(place : any, componentTemplate : any) {
    let result;

    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (componentTemplate[addressType]) {
        result = place.address_components[i][componentTemplate[addressType]];
        return result;
      }
    }
    return;
  }

  getAddress(place: any) {
    this.address = place;
    this.formattedAddress = place['formatted_address'];
    this._ngZone.run(() => this.formattedAddress = place['formatted_address']);
  }

  getCountry(place: any) {
    const COMPONENT_TEMPLATE = { country: 'long_name' },
      country = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return country;
  }

  getCity(place: any) {
    const COMPONENT_TEMPLATE = { postal_town: 'long_name' },
      city = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return city;
  }

  getStreetNumber(place : any) {
    const COMPONENT_TEMPLATE = { street_number: 'short_name' },
      streetNumber = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return streetNumber;
  }

  getStreet(place : any) {
    const COMPONENT_TEMPLATE = { route: 'long_name' },
      street = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return street;
  }

  getPostCode(place : any) {
    const COMPONENT_TEMPLATE = { postal_code: 'long_name' },
      postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return postCode;
  }

  public submitForm() {
    const d = moment(this.date.value);
    this.newItem.date = Timestamp.fromDate(d.toDate());

    this.newItem.material = this.materials;
    try {
      this.newItem.country = this.getCountry(this.address) ? this.getCountry(this.address) : ''
      this.newItem.city = this.getCity(this.address) ? this.getCity(this.address) : ''
      this.newItem.firstLineAddress = this.getStreet(this.address) ? this.getStreetNumber(this.address) + ' ' + this.getStreet(this.address) : ''
      this.newItem.postcode = this.getPostCode(this.address) ? this.getPostCode(this.address) : ''
    } catch {
      return
    }

    this.listButton.disabled = true;
    this.listing  = true;

    this.newItem.photos = [...Array(this.imgFiles.length).keys()];
    this.newItem.titleList = this.newItem.title.toLowerCase().split(' ');
    
    this.itemService.createItemFirebase(this.newItem).then(response => {
      // upload image files
      this.itemService.uploadItemImages(response, this.imgFiles).then(function(){
        var url = "/";
        window.location.href = url;
      })
    });
  }
}
