<nav class="navbar navbar-expand-lg navbar-light fixed-top py-3 d-block" data-navbar-on-scroll="data-navbar-on-scroll">
  <div class="container" style="background-color: #ffccae;">
    <a class="navbar-brand d-inline-flex" href="#">
      <img class="d-inline-block logo" src="/static/assets/img/logo.png" alt="logo" />
      <span class="text-1000 fs-0 fw-bold ms-2 logo-text">Antiqry</span>
    </a>
    <button class="navbar-toggler collapsed" type="button"
    data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
    aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
    <div class="collapse navbar-collapse border-lg-0 mt-4 mt-lg-0" id="navbarSupportedContent" style="flex-grow: 0 !important;">
      <form class="d-flex">
        <a class="text-1000" *ngIf="user" href="/profile"> 
          <div class="user-name text-1000">
            {{user.displayName}}
          </div>
        </a>
        <!--<a class="text-1000" href="/notif">
          <div class="menu-item text-1000">
            <span class="material-symbols-outlined">
              notifications
            </span>
          </div>
        </a>-->
        <a class="text-1000" title="List item" href="/items/list">
          <div class="menu-item text-1000">
            <span class="material-symbols-outlined">
              list_alt_add
            </span>
          </div>
        </a>
        <a class="text-1000" title="Offers" href="/offers">
          <div class="menu-item text-1000">
            <span class="material-symbols-outlined">
              sync_alt
            </span>
          </div>
        </a>
        <a class="text-1000" title="Search" href="/items/search">
          <div class="menu-item text-1000">
            <span class="material-symbols-outlined">
              search
            </span>
          </div>
        </a>
        <a class="text-1000" title="Loved" href="/items/loved">
          <div class="menu-item text-1000">
            <span class="material-symbols-outlined">
              shield_with_heart
            </span>
          </div>
        </a>
        <a class="text-1000" *ngIf="!user" title="Login" href="/login">
          <div class="menu-item text-1000">
            <span class="material-symbols-outlined">
              login
            </span>
          </div>
        </a>
        <div class="text-1000" *ngIf="user" (click)="logoutUser()">
          <span class="menu-item  material-symbols-outlined">
            logout
          </span>
        </div>
      </form>
    </div>
  </div>
</nav>
