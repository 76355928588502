<!--/.bg-holder-->

<div class="container">
  <div class="row flex-center">
    <div class="d-flex align-items-center flex-column">
      <p class="create-account-txt"> Hi, sign in or create an account. </p>
    </div>
    <div class="form-container">
      <app-email></app-email>
      <p class="create-account-txt"> or </p>
      <div>
        <button mat-button (click)="loginGoogle()" class="google">Continue with Google</button>
        <a routerLink="/signup" routerLinkActive="active" class="create-account-txt">
          No account? <strong>Create one here</strong>
        </a>
      </div>
    </div>
  </div>
</div>

