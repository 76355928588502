<div class="offer-card">
    <div class="thumbnail-container">
        <img [src]="offer!.thumbnailUrl" alt="Item Thumbnail" class="thumbnail">
    </div>
    <div class="column">
        <h5>{{ offer!.itemName }}</h5>
        <p>{{ offer!.emitterId === user?.uid ? 'Buy' : 'Sell'}} ({{ offer!.state === 'accepted' ? 'Waiting for payment'
            : offer!.state }})</p>
    </div>

    <div class="column">
        <div class="col-6 col-md-12">
            <p>{{ offer!.created!.toDate() | date:'short' }}</p>
        </div>
        <div class="col-6 col-md-12">
            <p>{{ offer!.price }} USD</p>
        </div>
    </div>
    <div class="column">
        <div class="col-6 col-md-12">
            <p>{{ offer!.emitterName }}</p>
        </div>
    </div>
    <div class="column">
        <div *ngIf="offer!.state === 'accepted' && offer!.emitterId === user?.uid">
            <google-pay-button environment="TEST" [paymentRequest]="offer!.paymentRequest"
                (loadpaymentdata)="onLoadPaymentData($event)" buttonType="pay" buttonLocale="en" buttonSizeMode="fill"
                style="width: 160px; height: 40px;"></google-pay-button>
        </div>
    </div>
    <div class="column">
    
        <div *ngIf="offer!.emitterId === user?.uid">
            <button mat-raised-button color="warn" (click)="cancelOffer(offer)">Cancel</button>
        </div>
        <div *ngIf="offer!.receiverId === user?.uid">
            <button mat-raised-button (click)="acceptOffer(offer)">Accept</button>
            <button mat-raised-button color="warn" (click)="rejectOffer(offer)">Reject</button>
        </div>
    </div>

</div>