<div class="card card-span h-100 text-white">
  <div class="card-img-wrapper" style="background-image: url('{{ photo }}');"> 
    
  </div>
  <div class="card-img-overlay"> </div>
  <div class="card-body bg-200">
    <h5 class="fw-bold text-1000 text-truncate">{{ item!.title }}</h5>
    
    <div class="fw-bold">
      <span class="text-primary items-price" *ngIf="showPrice">${{ item!.price }}</span>
      <div class="fw-bold text-1000 text-truncate" style="float: right;">
        <p class="likes-text">{{ item!.likes }}</p>
        <span class="material-symbols-outlined">
          thumb_up
        </span>
      </div>
    </div>
  </div><a class="stretched-link" href="/items/{{ item!.id }}"></a>
</div>
