    <nav>
        <div class="nav nav-tabs majestic-tabs mb-4 justify-content-center" id="nav-tab" role="tablist"><button
            class="nav-link active" id="nav-women-tab" data-bs-toggle="tab" data-bs-target="#nav-women" type="button"
            role="tab" aria-controls="nav-women" aria-selected="true">My Items</button><button class="nav-link"
            id="nav-men-tab" data-bs-toggle="tab" data-bs-target="#nav-men" type="button" role="tab"
            aria-controls="nav-men" aria-selected="false">Loved Items</button></div>
        <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-women" role="tabpanel" aria-labelledby="nav-women-tab">
                <div class="row h-100 align-items-center g-2 myItems">
                    <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of myItems">
                        <app-simple-card [item]="item"></app-simple-card>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade show active" id="nav-women" role="tabpanel" aria-labelledby="nav-women-tab">
                <div class="row h-100 align-items-center g-2 myItems">
                    <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of lovedItems">
                        <app-simple-card [item]="item"></app-simple-card>
                    </div>
                </div>
            </div>
        </div>
    </nav>
