import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { User } from 'firebase/auth';
import { AuthService } from '../services/auth.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  public user: User | undefined;

  constructor(
    private authService: AuthService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    authService.user.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit (): void {
    let script = this._renderer2.createElement('script');
    script.type = `application/javascript`;
    script.src = `/assets/js/theme.js`;

    this._renderer2.appendChild(this._document.body, script);
  }

  logoutUser(): void {
    this.authService.logout();
    this.user = undefined;
  }
}
