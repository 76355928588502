<main class="main" id="top">
  <section class="py-11 bg-light-gradient border-bottom border-white border-5">
    <div class="bg-holder overlay overlay-light"
      style="background-image:url(assets/img/header-bg.png);background-size:cover;"></div>
    <!--/.bg-holder-->
    <div class="container">
      <div class="row flex-center">
        <div class="col-12 mb-10">
          <div class="d-flex align-items-center flex-column">
            <h1 class="fw-normal" style="padding-top: 50px"> Art and antiques market with historic tracking </h1>
            <h1 class="fs-4 fs-lg-8 fs-md-6 fw-bold">Where things turn legacy</h1>
          </div>
        </div>
      </div>
    </div>
  </section>
<!-- ============================================-->
<!-- <section> begin ============================-->
<section class="py-0" id="header" style="margin-top: -23rem !important;">
  <div class="container">
    <div class="row g-0">
      <div class="col-md-6">
        <div class="card card-span h-100 text-white"> <img class="img-fluid" src="/static/assets/img/Antiques.jpg" width="790"
            alt="..." />
          <div class="card-img-overlay d-flex flex-center"> <a class="btn btn-lg btn-light" href="/items/search?selling=false">Collections</a></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-span h-100 text-white"> <img class="img-fluid" src="/static/assets/img/237064-antique-markets-uk.jpg" width="790"
            alt="..." />
          <div class="card-img-overlay d-flex flex-center"> <a class="btn btn-lg btn-light" href="/items/search?selling=true">Antiques Market</a></div>
        </div>
      </div>
    </div>
  </div><!-- end of .container-->
</section><!-- <section> close ============================-->
<!-- ============================================-->

<!-- ============================================-->
<!-- <section> begin ============================-->
<section class="py-0">
  <div class="container">
    <div class="row h-100">
      <div class="col-lg-7 mx-auto text-center mt-7 mb-5">
        <h5 class="fw-bold fs-3 fs-lg-5 lh-sm">Best Deals</h5>
      </div>
      <div class="col-12">
        <div class="carousel slide" id="carouselBestDeals" data-bs-touch="false" data-bs-interval="false">
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="10000">
              <div class="row h-100 align-items-center g-2">
                <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100"  *ngFor="let item of bestDeals.slice(0,4)">
                  <app-simple-card [item]="item"></app-simple-card>
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="5000">
              <div class="row h-100 align-items-center g-2">
                <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100"  *ngFor="let item of bestDeals.slice(4,8)">
                  <app-simple-card [item]="item"></app-simple-card>
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <div class="row h-100 align-items-center g-2">
                <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100"  *ngFor="let item of bestDeals.slice(8,12)">
                  <app-simple-card [item]="item"></app-simple-card>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row h-100 align-items-center g-2">
                <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100"  *ngFor="let item of bestDeals.slice(12,16)">
                  <app-simple-card [item]="item"></app-simple-card>
                </div>
              </div>
            </div>
            <div class="row"><button class="carousel-control-prev" type="button" data-bs-target="#carouselBestDeals"
                data-bs-slide="prev"><span class="carousel-control-prev-icon" aria-hidden="true"></span><span
                  class="visually-hidden">Previous</span></button><button class="carousel-control-next" type="button"
                data-bs-target="#carouselBestDeals" data-bs-slide="next"><span class="carousel-control-next-icon"
                  aria-hidden="true"></span><span class="visually-hidden">Next </span></button></div>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-center mt-5"> <a class="btn btn-lg btn-dark" href="/items/search?selling=true">View All </a>
      </div>
    </div>
  </div><!-- end of .container-->
</section><!-- <section> close ============================-->
<!-- ============================================-->



<!-- ============================================-->
<!-- <section> begin ============================-->
<section>
  <div class="container">
    <div class="row h-100 g-0">
      <div class="col-md-6">
        <div class="bg-300 p-4 h-100 d-flex flex-column justify-content-center">
          <h4 class="text-800">Value that grows with time</h4>
          <h1 class="fw-semi-bold lh-sm fs-4 fs-lg-5 fs-xl-6">Admire and desire</h1>
          <p class="mb-5 fs-1">There is a place for all those things that get better with time, where stories enrich our items,
            where buying is just the beginning and things never get lost in history.</p>
          <div class="d-grid gap-2 d-md-block"><a class="btn btn-lg btn-dark" href="/items/search?selling=false" role="button">Explore</a></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-span h-100 text-white"><img class="card-img h-100" src="/static/assets/img/outfit.png"
            alt="..." />
          <div class="card-img-overlay bg-dark-gradient">
            <div class="d-flex align-items-end justify-content-center h-100"><a class="btn btn-lg text-light fs-1"
                href="/items/search?selling=true&category=Home,%20Furniture%20%26%20DIY" role="button">Home, Furniture & DIY<svg class="bi bi-arrow-right-short" xmlns="http://www.w3.org/2000/svg"
                  width="23" height="23" fill="currentColor" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z">
                  </path>
                </svg></a></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row h-100 g-2 py-1">
      <div class="col-md-4">
        <div class="card card-span h-100 text-white"><img class="card-img h-100" src="/static/assets/img/art.png"
            alt="..." />
          <div class="card-img-overlay bg-dark-gradient">
            <div class="d-flex align-items-end justify-content-center h-100"><a class="btn btn-lg text-light fs-1"
                href="/items/search?selling=true&category=Art&condition=all" role="button">Art<svg class="bi bi-arrow-right-short"
                  xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z">
                  </path>
                </svg></a></div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-span h-100 text-white"><img class="card-img h-100" src="/static/assets/img/watches.png" alt="..." />
          <div class="card-img-overlay bg-dark-gradient">
            <div class="d-flex align-items-end justify-content-center h-100"><a class="btn btn-lg text-light fs-1"
                href="/items/search?selling=true&category=Jewellery%20%26%20Watches&condition=all" role="button">Jewellery & Watches<svg class="bi bi-arrow-right-short" xmlns="http://www.w3.org/2000/svg"
                  width="23" height="23" fill="currentColor" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z">
                  </path>
                </svg></a></div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-span h-100 text-white"><img class="card-img h-100" src="/static/assets/img/music.png"
            alt="..." />
          <div class="card-img-overlay bg-dark-gradient">
            <div class="d-flex align-items-end justify-content-center h-100"><a class="btn btn-lg text-light fs-1"
                href="/items/search?selling=true&category=Music&condition=all" role="button">Music<svg class="bi bi-arrow-right-short"
                  xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z">
                  </path>
                </svg></a></div>
          </div>
        </div>
      </div>
    </div>
  </div><!-- end of .container-->
</section><!-- <section> close ============================-->
<!-- ============================================-->

<section class="py-0">
  <div class="container">
    <div class="row h-100">
      <div class="col-lg-7 mx-auto text-center mb-6">
        <h5 class="fs-3 fs-lg-5 lh-sm mb-3">Gallery New Arrivals</h5>
      </div>
      <div class="col-12">
        <div class="carousel slide" id="carouselNewArrivals" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="10000">
              <div class="row h-100 align-items-center g-2">
                <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of newArrivals.slice(0,4)">
                  <app-bigimage-simple-card [item]="item"></app-bigimage-simple-card>
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="5000">
              <div class="row h-100 align-items-center g-2">
                <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of newArrivals.slice(4,8)">
                  <app-bigimage-simple-card [item]="item"></app-bigimage-simple-card>
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <div class="row h-100 align-items-center g-2">
                <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of newArrivals.slice(8,12)">
                  <app-bigimage-simple-card [item]="item"></app-bigimage-simple-card>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row h-100 align-items-center g-2">
                <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of newArrivals.slice(12,16)">
                  <app-bigimage-simple-card [item]="item"></app-bigimage-simple-card>
                </div>
              </div>
            </div>
            <div class="row"><button class="carousel-control-prev" type="button" data-bs-target="#carouselNewArrivals"
                data-bs-slide="prev"><span class="carousel-control-prev-icon" aria-hidden="true"></span><span
                  class="visually-hidden">Previous</span></button><button class="carousel-control-next" type="button"
                data-bs-target="#carouselNewArrivals" data-bs-slide="next"><span class="carousel-control-next-icon"
                  aria-hidden="true"></span><span class="visually-hidden">Next </span></button></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="categoryWomen">
  <div class="container">
    <div class="row h-100">
      <div class="col-lg-7 mx-auto text-center mb-6">
        <h5 class="fw-bold fs-3 fs-lg-5 lh-sm mb-3">Shop By Category</h5>
      </div>
      <div class="col-12">
        <nav>
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-women" role="tabpanel" aria-labelledby="nav-women-tab">
              <ul class="nav nav-pills justify-content-center mb-5" id="pills-tab-women" role="tablist">
                <li class="nav-item" role="presentation"><button class="nav-link active" id="pills-wtshirt-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-wtshirt" type="button" role="tab" aria-controls="pills-wtshirt" aria-selected="true"
                    (click)="shopByQuery('Antiques')">Antiques</button></li>
                <li class="nav-item" role="presentation"><button class="nav-link" id="pills-dresses-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-dresses" type="button" role="tab" aria-controls="pills-dresses" aria-selected="false"
                    (click)="shopByQuery('Art')">Art</button></li>
                <li class="nav-item" role="presentation"><button class="nav-link" id="pills-wshoes-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-wshoes" type="button" role="tab" aria-controls="pills-wshoes" aria-selected="false"
                    (click)="shopByQuery('Jewellery & Watches')">Jewellery & Watches</button></li>
                <li class="nav-item" role="presentation"><button class="nav-link" id="pills-wwatch-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-wwatch" type="button" role="tab" aria-controls="pills-wwatch" aria-selected="false"
                    (click)="shopByQuery('Music')">Music
                  </button></li>
                <li class="nav-item" role="presentation"><button class="nav-link" id="pills-wsunglasses-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-wsunglasses" type="button" role="tab" aria-controls="pills-wsunglasses"
                    aria-selected="false" (click)="shopByQuery('Coins')">Coins</button></li>
                <li class="nav-item" role="presentation"><button class="nav-link" id="pills-wbagpacks-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-wbagpacks" type="button" role="tab" aria-controls="pills-wbagpacks" aria-selected="false"
                    (click)="shopByQuery('Home, Furniture & DIY')">Home, Furniture & DIY</button></li>
              </ul>
              <div class="tab-content" id="pills-tabContentWomen">
                <div class="tab-pane fade" id="pills-dresses" role="tabpanel" aria-labelledby="pills-dresses-tab">
                  <div class="carousel slide" id="carouselCategoryDresses" data-bs-touch="false"
                    data-bs-interval="false">
                    <div class="carousel-inner">
                      <div class="carousel-item active" data-bs-interval="10000">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(0,4)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item" data-bs-interval="5000">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(4,8)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item" data-bs-interval="3000">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(8,12)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(12,16)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="row"><button class="carousel-control-prev" type="button"
                          data-bs-target="#carouselCategoryDresses" data-bs-slide="prev"><span
                            class="carousel-control-prev-icon" aria-hidden="true"></span><span
                            class="visually-hidden">Previous</span></button><button class="carousel-control-next"
                          type="button" data-bs-target="#carouselCategoryDresses" data-bs-slide="next"><span
                            class="carousel-control-next-icon" aria-hidden="true"></span><span
                            class="visually-hidden">Next </span></button></div>
                    </div>
                  </div>
                  <div class="col-12 d-flex justify-content-center mt-5"> <a class="btn btn-lg btn-dark" href="/items/search?selling=true&category=Antiques&condition=all">View
                      All </a></div>
                </div>
                <div class="tab-pane fade show active" id="pills-wtshirt" role="tabpanel"
                  aria-labelledby="pills-wtshirt-tab">
                  <div class="carousel slide" id="carouselCategoryWTshirt" data-bs-touch="false"
                    data-bs-interval="false">
                    <div class="carousel-inner">
                      <div class="carousel-item active" data-bs-interval="10000">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(0,4)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item" data-bs-interval="5000">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(4,8)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item" data-bs-interval="3000">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(8,12)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(12,16)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="row"><button class="carousel-control-prev" type="button"
                          data-bs-target="#carouselCategoryWTshirt" data-bs-slide="prev"><span
                            class="carousel-control-prev-icon" aria-hidden="true"></span><span
                            class="visually-hidden">Previous</span></button><button class="carousel-control-next"
                          type="button" data-bs-target="#carouselCategoryWTshirt" data-bs-slide="next"><span
                            class="carousel-control-next-icon" aria-hidden="true"></span><span
                            class="visually-hidden">Next </span></button></div>
                    </div>
                  </div>
                  <div class="col-12 d-flex justify-content-center mt-5"> <a class="btn btn-lg btn-dark" href="/items/search?selling=true&category=Art&condition=all">View
                      All </a></div>
                </div>
                <div class="tab-pane fade" id="pills-wshoes" role="tabpanel" aria-labelledby="pills-wshoes-tab">
                  <div class="carousel slide" id="carouselCategoryWShoes" data-bs-touch="false"
                    data-bs-interval="false">
                    <div class="carousel-inner">
                      <div class="carousel-item active" data-bs-interval="10000">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(0,4)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item" data-bs-interval="5000">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(4,8)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item" data-bs-interval="3000">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(8,12)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(12,16)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="row"><button class="carousel-control-prev" type="button"
                          data-bs-target="#carouselCategoryWShoes" data-bs-slide="prev"><span
                            class="carousel-control-prev-icon" aria-hidden="true"></span><span
                            class="visually-hidden">Previous</span></button><button class="carousel-control-next"
                          type="button" data-bs-target="#carouselCategoryWShoes" data-bs-slide="next"><span
                            class="carousel-control-next-icon" aria-hidden="true"></span><span
                            class="visually-hidden">Next </span></button></div>
                    </div>
                  </div>
                  <div class="col-12 d-flex justify-content-center mt-5"> <a class="btn btn-lg btn-dark" href="/items/search?selling=true&category=Jewellery%20%26%20Watches&condition=all">View
                      All </a></div>
                </div>
                <div class="tab-pane fade" id="pills-wwatch" role="tabpanel" aria-labelledby="pills-wwatch-tab">
                  <div class="carousel slide" id="carouselCategoryWwatch" data-bs-touch="false"
                    data-bs-interval="false">
                    <div class="carousel-inner">
                      <div class="carousel-item active" data-bs-interval="10000">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(0,4)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item" data-bs-interval="5000">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(4,8)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item" data-bs-interval="3000">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(8,12)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(12,16)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="row"><button class="carousel-control-prev" type="button"
                          data-bs-target="#carouselCategoryWwatch" data-bs-slide="prev"><span
                            class="carousel-control-prev-icon" aria-hidden="true"></span><span
                            class="visually-hidden">Previous</span></button><button class="carousel-control-next"
                          type="button" data-bs-target="#carouselCategoryWwatch" data-bs-slide="next"><span
                            class="carousel-control-next-icon" aria-hidden="true"></span><span
                            class="visually-hidden">Next </span></button></div>
                    </div>
                  </div>
                  <div class="col-12 d-flex justify-content-center mt-5"> <a class="btn btn-lg btn-dark" href="/items/search?selling=true&category=Music&condition=all">View
                      All </a></div>
                </div>
                <div class="tab-pane fade" id="pills-wsunglasses" role="tabpanel"
                  aria-labelledby="pills-wsunglasses-tab">
                  <div class="carousel slide" id="carouselCategoryWSunglasses" data-bs-touch="false"
                    data-bs-interval="false">
                    <div class="carousel-inner">
                      <div class="carousel-item active" data-bs-interval="10000">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(0,4)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item" data-bs-interval="5000">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(4,8)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item" data-bs-interval="3000">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(8,12)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(12,16)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="row"><button class="carousel-control-prev" type="button"
                          data-bs-target="#carouselCategoryWSunglasses" data-bs-slide="prev"><span
                            class="carousel-control-prev-icon" aria-hidden="true"></span><span
                            class="visually-hidden">Previous</span></button><button class="carousel-control-next"
                          type="button" data-bs-target="#carouselCategoryWSunglasses" data-bs-slide="next"><span
                            class="carousel-control-next-icon" aria-hidden="true"></span><span
                            class="visually-hidden">Next </span></button></div>
                    </div>
                  </div>
                  <div class="col-12 d-flex justify-content-center mt-5"> <a class="btn btn-lg btn-dark" href="/items/search?selling=true&category=Coins&condition=all">View
                      All </a></div>
                </div>
                <div class="tab-pane fade" id="pills-wbagpacks" role="tabpanel" aria-labelledby="pills-wbagpacks-tab">
                  <div class="carousel slide" id="carouselCategoryWBagpacks" data-bs-touch="false"
                    data-bs-interval="false">
                    <div class="carousel-inner">
                      <div class="carousel-item active" data-bs-interval="10000">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(0,4)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item" data-bs-interval="5000">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(4,8)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item" data-bs-interval="3000">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(8,12)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="row h-100 align-items-center g-2">
                          <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of shopByItems.slice(12,16)">
                            <app-simple-card [item]="item"></app-simple-card>
                          </div>
                        </div>
                      </div>
                      <div class="row"><button class="carousel-control-prev" type="button"
                          data-bs-target="#carouselCategoryWBagpacks" data-bs-slide="prev"><span
                            class="carousel-control-prev-icon" aria-hidden="true"></span><span
                            class="visually-hidden">Previous</span></button><button class="carousel-control-next"
                          type="button" data-bs-target="#carouselCategoryWBagpacks" data-bs-slide="next"><span
                            class="carousel-control-next-icon" aria-hidden="true"></span><span
                            class="visually-hidden">Next </span></button></div>
                    </div>
                  </div>
                  <div class="col-12 d-flex justify-content-center mt-5"> <a class="btn btn-lg btn-dark" href="/items/search?selling=true&category=Home,%20Furniture%20%26%20DIY&condition=all">View
                      All </a></div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</section>

<!-- ============================================-->
<!-- <section> begin ============================-->
<section class="py-0" id="collection">
  <div class="container">
    <div class="row h-100 gx-2">
      <div class="col-md-6">
        <div class="card card-span h-100 text-white"><img class="card-img h-100" src="/static/assets/img/urban.png" alt="..." />
          <div class="card-img-overlay bg-dark-gradient">
            <div class="p-5 p-md-2 p-xl-5">
              <h1 class="fs-md-4 fs-lg-7 text-light">Urban Stories </h1>
              <h5 class="fs-2 text-light">collection</h5>
            </div>
          </div><a class="stretched-link" href="/items/search?selling=false&category=all&condition=two"></a>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-span h-100 text-white"><img class="card-img h-100" src="/static/assets/img/country.png"
            alt="..." />
          <div class="card-img-overlay bg-dark-gradient">
            <div class="p-5 p-md-2 p-xl-5 d-flex flex-column flex-end-center align-items-baseline h-100">
              <h1 class="fs-md-4 fs-lg-7 text-light">Classic Stories </h1>
              <h5 class="fs-2 text-light">collection</h5>
            </div>
          </div><a class="stretched-link" href="/items/search?selling=false&category=all&condition=one"></a>
        </div>
      </div>
    </div>
  </div><!-- end of .container-->
</section><!-- <section> close ============================-->
<!-- ============================================-->

<!-- ============================================-->
<!-- <section> begin ============================-->
<section class="py-0" id="outlet">
  <div class="container">
    <div class="row h-100 g-0">
      <div class="col-md-6">
        <div class="card card-span h-100 text-white"><img class="card-img h-100" src="/static/assets/img/summer.png"
            alt="..." />
          <div class="card-img-overlay bg-dark-gradient rounded-0">
            <div class="p-5 p-md-2 p-xl-5 d-flex flex-column flex-end-center align-items-baseline h-100">
              <h1 class="fs-md-4 fs-lg-7 text-light">20th century</h1>
            </div>
          </div><a class="stretched-link" href="/items/search"></a>
        </div>
      </div>
      <div class="col-md-6 h-100">
        <div class="row h-100 g-0">
          <div class="col-md-6 h-100">
            <div class="card card-span h-100 text-white"><img class="card-img h-100" src="/static/assets/img/music.png"
                alt="..." />
              <div class="card-img-overlay bg-dark-gradient rounded-0">
                <div class="p-5 p-xl-5 p-md-0">
                  <h3 class="text-light">Music</h3>
                </div>
              </div><a class="stretched-link" href="/items/search?selling=false&category=Music"></a>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card card-span h-100 text-white"><img class="card-img h-100" src="/static/assets/img/outfit.png"
                alt="..." />
              <div class="card-img-overlay bg-dark-gradient rounded-0">
                <div class="p-5 p-xl-5 p-md-0">
                  <h3 class="text-light">Furniture</h3>
                </div>
              </div><a class="stretched-link" href="/items/search?selling=false&category=Home,%20Furniture%20%26%20DIY"></a>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card card-span h-100 text-white"><img class="card-img h-100"
                src="/static/assets/img/art.png" alt="..." />
              <div class="card-img-overlay bg-dark-gradient rounded-0">
                <div class="p-5 p-xl-5 p-md-0">
                  <h3 class="text-light">Art</h3>
                </div>
              </div><a class="stretched-link" href="/items/search?selling=false&category=Art&condition=all"></a>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card card-span h-100 text-white"><img class="card-img h-100" src="/static/assets/img/watches.png"
                alt="..." />
              <div class="card-img-overlay bg-dark-gradient rounded-0">
                <div class="p-5 p-xl-5 p-md-0">
                  <h3 class="text-light">Watches</h3>
                </div>
              </div><a class="stretched-link" href="/items/search?selling=false&category=Jewellery%20%26%20Watches&condition=all"> </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div><!-- end of .container-->
</section><!-- <section> close ============================-->
<!-- ============================================-->



<!-- ============================================-->
<!-- <section> begin ============================-->
<section>
  <div class="container">
    <div class="row h-100 g-0">
      <div class="col-md-6">
        <div class="bg-300 p-4 h-100 d-flex flex-column justify-content-center">
          <h1 class="fw-semi-bold lh-sm fs-4 fs-lg-5 fs-xl-6">Private Collections</h1>
          <p class="mb-5 fs-1">Explore private collections that are not available for selling today but might be in the future.</p>
          <div class="d-grid gap-2 d-md-block"><a class="btn btn-lg btn-dark" href="#!" role="button">Explore
              Collection</a></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-span h-100 text-white"><img class="card-img h-100" src="/static/assets/img/sharp-dress.png"
            alt="..." /><a class="stretched-link" href="/items/search?selling=false&category=all&condition=all"></a></div>
      </div>
    </div>
  </div><!-- end of .container-->
</section><!-- <section> close ============================-->
<!-- ============================================-->



<!-- ============================================-->
<!-- <section> begin ============================-->
<section class="py-0 pb-8">
  <div class="container-fluid container-lg" hidden>
    <div class="row h-100 g-2 justify-content-center">
      <div class="col-sm-9 col-md-4 mb-3 mb-md-0 h-100">
        <div class="card card-span text-white h-100"><img class="img-card h-100" src="/static/assets/img/shoes-blog-1.png"
            alt="..." />
          <div class="card-body px-xl-5 px-md-3 pt-0 pb-7">
            <div class="d-flex justify-content-between align-items-center bg-100 mt-n5 me-auto"><img
                src="/static/assets/img/author-1.png" width="60" alt="..." />
              <div class="d-flex flex-1 justify-content-around"> <span class="text-900 text-center"><i
                    data-feather="eye"> </i><span class="text-900 ms-2">35</span></span><span
                  class="text-900 text-center"><i data-feather="heart"> </i><span
                    class="text-900 ms-2">23</span></span><span class="text-900 text-center"><i
                    data-feather="corner-up-right"> </i><span class="text-900 ms-2">14</span></span></div>
            </div>
            <h6 class="text-900 mt-3">Kelly Hudson . <span class="fw-normal">Fashion actiKelly Hudson . </span></h6>
            <h3 class="fw-bold text-1000 mt-5 text-truncate">How important are shoes in your style?</h3>
            <p class="text-900 mt-3">Is it possible to assess a person just on the basis of their footwear? Obviously,
              nobody should criticize, but certainly, shoes say a lot about someone. It matters for the outsiders that
              we meet every day...</p><a class="btn btn-lg text-900 fs-1 px-0 hvr-icon-forward" href="#!"
              role="button">Read more<svg class="bi bi-arrow-right-short hover-icon" xmlns="http://www.w3.org/2000/svg"
                width="30" height="30" fill="currentColor" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z">
                </path>
              </svg></a>
          </div>
        </div>
      </div>
      <div class="col-sm-9 col-md-4 mb-3 mb-md-0 h-100">
        <div class="card card-span text-white h-100"><img class="img-card h-100" src="/static/assets/img/fashion-blog-2.png"
            alt="..." />
          <div class="card-body px-xl-5 px-md-3 pt-0 pb-7">
            <div class="d-flex justify-content-between align-items-center bg-100 mt-n5 me-auto"><img
                src="/static/assets/img/author-2.png" width="60" alt="..." />
              <div class="d-flex flex-1 justify-content-around"> <span class="text-900 text-center"><i
                    data-feather="eye"> </i><span class="text-900 ms-2">35</span></span><span
                  class="text-900 text-center"><i data-feather="heart"> </i><span
                    class="text-900 ms-2">23</span></span><span class="text-900 text-center"><i
                    data-feather="corner-up-right"> </i><span class="text-900 ms-2">14</span></span></div>
            </div>
            <h6 class="text-900 mt-3">Rotondwa Johnny . <span class="fw-normal">Fashion activist </span></h6>
            <h3 class="fw-bold text-1000 mt-5 text-truncate">Fashion trend forecast for Summer 2021</h3>
            <p class="text-900 mt-3">While the fashion industry has had a calm year, this season has seen some beautiful
              pieces. Over the previous several weeks, commanding coats, and elegant face masks have ruled Fashion
              Weeks...</p><a class="btn btn-lg text-900 fs-1 px-0 hvr-icon-forward" href="#!" role="button">Read
              more<svg class="bi bi-arrow-right-short hover-icon" xmlns="http://www.w3.org/2000/svg" width="30"
                height="30" fill="currentColor" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z">
                </path>
              </svg></a>
          </div>
        </div>
      </div>
      <div class="col-sm-9 col-md-4 mb-3 mb-md-0 h-100">
        <div class="card card-span text-white h-100"><img class="img-card h-100"
            src="/static/assets/img/spring-dress-blog-3.png" alt="..." />
          <div class="card-body px-xl-5 px-md-3 pt-0 pb-7">
            <div class="d-flex justify-content-between align-items-center bg-100 mt-n5 me-auto"><img
                src="/static/assets/img/author-3.png" width="60" alt="..." />
              <div class="d-flex flex-1 justify-content-around"> <span class="text-900 text-center"><i
                    data-feather="eye"> </i><span class="text-900 ms-2">35</span></span><span
                  class="text-900 text-center"><i data-feather="heart"> </i><span
                    class="text-900 ms-2">23</span></span><span class="text-900 text-center"><i
                    data-feather="corner-up-right"> </i><span class="text-900 ms-2">14</span></span></div>
            </div>
            <h6 class="text-900 mt-3">Martin . <span class="fw-normal">Fashion activist </span></h6>
            <h3 class="fw-bold text-1000 mt-5 text-truncate">Spring exclusive collection Gallery &amp; Women</h3>
            <p class="text-900 mt-3">Explore the first real-time photographic fashion magazine NOWFASHION to broadcast
              exclusive live fashion shows. Some of the most beautiful spring collection i want to share. See the....
            </p><a class="btn btn-lg text-900 fs-1 px-0 hvr-icon-forward" href="#!" role="button">Read more<svg
                class="bi bi-arrow-right-short hover-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                fill="currentColor" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z">
                </path>
              </svg></a>
          </div>
        </div>
      </div>
    </div>
  </div><!-- end of .container-->
</section><!-- <section> close ============================-->
<!-- ============================================-->

<section class="py-11" style="padding-bottom: 0px;">
  <div class="bg-holder overlay overlay-0"
    style="background-image:url(assets/img/cta.png);background-position:center;background-size:cover;"></div>
  <!--/.bg-holder-->
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="carousel slide carousel-fade" id="carouseCta" data-bs-ride="carousel">
          <div class="carousel-inner" style="padding-top: 50px">
            <div class="carousel-item active" data-bs-interval="10000">
              <div class="row h-100 align-items-center g-2">
                <div class="col-12">
                  <div class="text-light text-center py-2">
                    <h5 class="display-4 fw-normal text-400 fw-normal mb-4">Navigate to Local stores</h5>
                    <h1 class="display-1 text-white fw-normal mb-8">London</h1><a class="btn btn-lg text-light fs-1"
                      href="/items/search?selling=false&category=all&condition=all" role="button">Explore<svg class="bi bi-arrow-right-short"
                        xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor"
                        viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z">
                        </path>
                      </svg></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="5000">
              <div class="row h-100 align-items-center g-2">
                <div class="col-12">
                  <div class="text-light text-center py-2">
                    <h5 class="display-4 fw-normal text-400 fw-normal mb-4">Navigate to Local stores</h5>
                    <h1 class="display-1 text-white fw-normal mb-8">New York</h1><a class="btn btn-lg text-light fs-1"
                      href="/items/search?selling=false&category=all&condition=all" role="button">Explore<svg class="bi bi-arrow-right-short"
                        xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor"
                        viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z">
                        </path>
                      </svg></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="3000">
              <div class="row h-100 align-items-center g-2">
                <div class="col-12">
                  <div class="text-light text-center py-2">
                    <h5 class="display-4 fw-normal text-400 fw-normal mb-4">Navigate to Local stores</h5>
                    <h1 class="display-1 text-white fw-normal mb-8">Shanghai</h1><a class="btn btn-lg text-light fs-1"
                      href="/items/search?selling=false&category=all&condition=all" role="button">Explore<svg class="bi bi-arrow-right-short"
                        xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor"
                        viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z">
                        </path>
                      </svg></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row"><button class="carousel-control-prev" type="button" data-bs-target="#carouseCta"
                data-bs-slide="prev"><span class="carousel-control-prev-icon" aria-hidden="true"></span><span
                  class="visually-hidden">Previous</span></button><button class="carousel-control-next" type="button"
                data-bs-target="#carouseCta" data-bs-slide="next"><span class="carousel-control-next-icon"
                  aria-hidden="true"></span><span class="visually-hidden">Next </span></button></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer hidden></app-footer>

</main>