<!-- ============================================-->
      <!-- <section> begin ============================-->
        <section class="py-0 pt-7">
            <div class="container">
              <div class="row">
                <div class="col-6 col-lg-2 mb-3">
                  <h5 class="lh-lg fw-bold text-1000">Company Info</h5>
                  <ul class="list-unstyled mb-md-4 mb-lg-0">
                    <li class="lh-lg"><a class="text-800 text-decoration-none" href="#!">About Us</a></li>
                    <li class="lh-lg"><a class="text-800 text-decoration-none" href="#!">Affiliate</a></li>
                    <li class="lh-lg"><a class="text-800 text-decoration-none" href="#!">Fashion Blogger</a></li>
                  </ul>
                </div>
                <div class="col-6 col-lg-2 mb-3">
                  <h5 class="lh-lg fw-bold text-1000">Help &amp; Support</h5>
                  <ul class="list-unstyled mb-md-4 mb-lg-0">
                    <li class="lh-lg"><a class="text-800 text-decoration-none" href="#!">Shipping Info</a></li>
                    <li class="lh-lg"><a class="text-800 text-decoration-none" href="#!">Refunds</a></li>
                    <li class="lh-lg"><a class="text-800 text-decoration-none" href="#!">How to Order</a></li>
                    <li class="lh-lg"><a class="text-800 text-decoration-none" href="#!">How to Track</a></li>
                    <li class="lh-lg"><a class="text-800 text-decoration-none" href="#!">Size Guides</a></li>
                  </ul>
                </div>
                <div class="col-6 col-lg-2 mb-3">
                  <h5 class="lh-lg fw-bold text-1000">Customer Care</h5>
                  <ul class="list-unstyled mb-md-4 mb-lg-0">
                    <li class="lh-lg"><a class="text-800 text-decoration-none" href="#!">Contact Us</a></li>
                    <li class="lh-lg"><a class="text-800 text-decoration-none" href="#!">Payment Methods</a></li>
                    <li class="lh-lg"><a class="text-800 text-decoration-none" href="#!">Bonus Point</a></li>
                  </ul>
                </div>
                <div class="col-sm-6 col-lg-auto ms-auto">
                  <h5 class="lh-lg fw-bold text-1000">Signup For The Latest News</h5>
                  <div class="row input-group-icon mb-5">
                    <div class="col-12"><input class="form-control input-box" type="email" placeholder="Enter Email" aria-label="email" /><svg class="bi bi-arrow-right-short input-box-icon" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="#424242" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"> </path>
                      </svg></div>
                  </div>
                  <p class="text-800"><svg class="feather feather-phone me-3" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                    </svg><span class="text-800">+111111111</span></p>
                  <p class="text-800"><svg class="feather feather-mail me-3" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                      <polyline points="22,6 12,13 2,6"></polyline>
                    </svg><span class="text-800">something@gmail.com</span></p>
                </div>
              </div>
              <div class="border-bottom border-3"></div>
              <div class="row flex-center my-3">
                <div class="col-md-6">
                  <div class="text-center text-md-end"><a href="#!"><span class="me-4" data-feather="facebook"></span></a><a href="#!"> <span class="me-4" data-feather="instagram"></span></a><a href="#!"> <span class="me-4" data-feather="youtube"></span></a><a href="#!"> <span class="me-4" data-feather="twitter"></span></a></div>
                </div>
              </div>
            </div><!-- end of .container-->
          </section><!-- <section> close ============================-->
          <!-- ============================================-->