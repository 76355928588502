<div class="container">
    <div class="searchContainer">
        <div class="searchIcon">
            <span (click)="search()" class="material-symbols-outlined">
                search 
            </span>
        </div>
        <input [(ngModel)]="searchTittle" (keyup.enter)="search()" class="searchBox" type="text" placeholder="Search">
        <div class="filterIcon">
            <span  (click)="toggleFilter()" class="material-symbols-outlined">
                filter_list
            </span>
        </div>
    </div>
    <div class="row h-100 align-items-center g-2 searchResult">
        <div class="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" *ngFor="let item of searchResult">
            <app-simple-card [item]="item"></app-simple-card>
        </div>
    </div>
    <div [ngClass]="[showFilter ? 'showFilter' : 'hideFilter']" class="filterContainer">
        <div style="padding: 10px">
            <p class="create-account-txt">Filters</p>
            <p>
                <mat-slide-toggle [(ngModel)]="searchFilter.selling" [color]="color">
                    Listed for selling
                </mat-slide-toggle>
            </p>
            <p>Categories:</p>
            <p class="category-selector">
                <mat-radio-group [(ngModel)]="searchFilter.category" name="category" aria-label="Select an option">
                    <div>
                        <mat-radio-button value="all">All</mat-radio-button>
                    </div>
                    <div
                        *ngFor="let item of categories">
                        <mat-radio-button value="{{ item }}">{{ item }}</mat-radio-button>
                    </div>
                </mat-radio-group>
            </p>
            <p>Condition:</p>
            <mat-select [(ngModel)]="searchFilter.condition" name="condition">
                <mat-option value="all">Any</mat-option>
                <mat-option value="one">Used</mat-option>
                <mat-option value="two">New with tags</mat-option>
                <mat-option value="two">New without tags</mat-option>
                <mat-option value="two">New with defects</mat-option>
            </mat-select>
            <button mat-stroked-button color="primary" class="closeFilter" (click)="toggleFilter()" >Close</button>
        </div>
    </div>
</div>