<div class="container">
    <div class="row flex-center">
        <p class="create-account-txt">Create your new listing. </p>
        <form class="example-form" #newForm="ngForm" (ngSubmit)="submitForm()">
            <p>
                <mat-form-field appearance="legacy">
                    <mat-label>Title</mat-label>
                    <input [(ngModel)]="newItem.title" name="title" matInput placeholder="Item title" max="80" required>
                    <mat-hint>80 characters max</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="legacy">
                    <mat-label>Subtitle</mat-label>
                    <input [(ngModel)]="newItem.subtitle"  name="subtitle" matInput placeholder="Item subtitle" max="50">
                    <mat-hint>50 characters max</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-slide-toggle [(ngModel)]="newItem.selling" name="selling" class="example-margin" [color]="color">
                    List for selling
                </mat-slide-toggle>
            </p>
            <p *ngIf="newItem.selling">
                <mat-form-field appearance="legacy">
                    <mat-label>Estimated value</mat-label>
                    <span matPrefix>$</span>
                    <input [(ngModel)]="newItem.price" name="price" type="number" matInput placeholder="">
                    <mat-icon matSuffix>mode_edit</mat-icon>
                  </mat-form-field>
            </p>
            <p>
                <mat-slide-toggle class="example-margin" [color]="color" [(ngModel)]="newItem.visible" name="visible">
                    Make it visible
                </mat-slide-toggle>
            </p>
            <p class="category-selector">
                <mat-radio-group [(ngModel)]="newItem.category" name="category" aria-label="Select an option">
                    <div
                        *ngFor="let item of categories">
                        <mat-radio-button value="{{ item }}">{{ item }}</mat-radio-button>
                    </div>
                </mat-radio-group>
            </p>
            <p>
                <mat-form-field appearance="fill">
                    <mat-label>Condition</mat-label>
                    <mat-select [(ngModel)]="newItem.condition" name="condition">
                        <mat-option value="one">Used</mat-option>
                        <mat-option value="two">New with tags</mat-option>
                        <mat-option value="two">New without tags</mat-option>
                        <mat-option value="two">New with defects</mat-option>
                    </mat-select>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="fill">
                    <mat-label>Condition description</mat-label>
                    <textarea [(ngModel)]="newItem.conditionDesc" name="conditionDesc" matInput></textarea>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="fill">
                    <mat-label>Description (detailed history can be added later)</mat-label>
                    <textarea matInput
                    [(ngModel)]="newItem.description" name="description"
                    cdkTextareaAutosize
                    #historyAutosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="10" required></textarea>
                </mat-form-field>
            </p>
            <p>
                <input id="actual-btn" class="custom-input" fileInput type="file" accept="image/png, image/jpeg" (change)="onImageUpload($event)" multiple hidden>
                <label class="upload-button" for="actual-btn">Upload Images</label>
                <span appearance="fill" class="row h-100 g-2 scroll-max">
                    <div
                        *ngFor="let image of imgFiles" class="col-sm-9 col-md-4 mb-3 mb-md-0 h-100">
                        <img [src]="image">
                    </div>
                </span>
            </p>
            <p>
                <app-autocomplete (setAddress)="getAddress($event)" adressType="geocode"></app-autocomplete>
            </p>
            <p>
                <mat-form-field appearance="legacy">
                    <mat-label>Culture of origen</mat-label>
                    <input id="culture" matInput
                    [(ngModel)]="newItem.culture" name="culture" placeholder="Culture of origen" max="80">
                    <mat-hint>80 characters max</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="legacy">
                    <mat-label>Brand</mat-label>
                    <input id="brand" matInput
                    [(ngModel)]="newItem.brand" name="brand" placeholder="Item brand" max="80">
                    <mat-hint>80 characters max</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="legacy">
                    <mat-label>Approximate time in history</mat-label>
                    <input autocomplete="off" matInput [matDatepicker]="dp" [formControl]="date">
                    <mat-hint>MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp
                                    startView="multi-year"
                                    (monthSelected)="setMonthAndYear($event, dp)"
                                    panelClass="example-month-picker">
                    </mat-datepicker>
                  </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="legacy">
                    <mat-chip-list #chipList aria-label="Material selection">
                        <mat-chip
                          *ngFor="let material of materials"
                          (removed)="remove(material)">
                          {{material}}
                          <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                          </button>
                        </mat-chip>
                        <input
                            placeholder="Material"
                            #materialInput
                            [formControl]="matCtrl"
                            [matAutocomplete]="auto"
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="add($event)">
                      </mat-chip-list>
                      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let material of filteredMaterial | async" [value]="material">
                          {{material}}
                        </mat-option>
                      </mat-autocomplete>
                </mat-form-field>
            </p>
            <div class="button-row" style="float: left;">
                <button mat-raised-button color="primary" #listButton type="submit">List</button>
                <button mat-stroked-button color="primary" onclick="window.location.href='/'">Cancel</button>
            </div>
            <mat-spinner *ngIf="listing" [diameter]="30"></mat-spinner>
        </form>
    </div>
</div>