import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore, enableMultiTabIndexedDbPersistence } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { EmailComponent } from './email/email.component';
import { ProfileComponent } from './profile/profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { AuthService } from './services/auth.service';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SimpleCardComponent } from './simple-card/simple-card.component';
import { ItemsService } from './services/items.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './_helpers/auth.interceptor';
import { ListItemComponent } from './list-item/list-item.component';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { AuthGuard } from '@angular/fire/auth-guard';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { BigimageSimpleCardComponent } from './bigimage-simple-card/bigimage-simple-card.component';
import { SearchComponent } from './search/search.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatGridListModule} from '@angular/material/grid-list';
import { MyItemsComponent } from './my-items/my-items.component';
import { ItemPageComponent } from './item-page/item-page.component';
import { PaymentsService } from './services/payments.service';
import { GooglePayButtonModule } from '@google-pay/button-angular';
import { OffersPageComponent } from './offers-page/offers-page.component';
import { OffersService } from './services/offers.service';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { OfferCardComponent } from './offer-card/offer-card.component';

let resolvePersistenceEnabled: (enabled: boolean) => void;

export const persistenceEnabled = new Promise<boolean>(resolve => {
  resolvePersistenceEnabled = resolve;
});

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    LoginComponent,
    SignUpComponent,
    EmailComponent,
    ProfileComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    SimpleCardComponent,
    ListItemComponent,
    AutocompleteComponent,
    BigimageSimpleCardComponent,
    SearchComponent,
    MyItemsComponent,
    ItemPageComponent,
    OffersPageComponent,
    OfferCardComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      const auth = getAuth();
      return auth;
    }),
    provideFirestore(() => {
      const firestore = getFirestore();
      enableMultiTabIndexedDbPersistence(firestore).then(
        () => resolvePersistenceEnabled(true),
        () => resolvePersistenceEnabled(false)
      );
      return firestore;
    }),
    provideDatabase(() => {
      const database = getDatabase();
      return database;
    }),
    provideStorage(() => {
      const storage = getStorage();
      return storage;
    }),
    provideFunctions(() => {
      const functions = getFunctions();
      return functions;
    }),
    AngularFireAuthModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatRadioModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatGridListModule,
    GooglePayButtonModule,
    MatTableModule,
    MatPaginatorModule
  ],
  exports: [ ],
  providers: [
    AuthService,
    AuthGuard,
    ItemsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    PaymentsService,
    OffersService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}