import { Component, OnInit } from '@angular/core';
import { NewItem } from '../models/new-item';
import { AuthService } from '../services/auth.service';
import { ItemsService } from '../services/items.service';

export interface ItemsList {
  items: NewItem[]
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  bestDeals : NewItem[] = [];
  newArrivals : NewItem[] = [];

  mAntiques : NewItem[] = [];
  gAntiques : NewItem[] = [];

  mBusiness : NewItem[] = [];
  gBusiness : NewItem[] = [];

  shopByItems : NewItem[] = [];

  constructor(private itemsService: ItemsService, private authService : AuthService) { }

  ngOnInit(): void {
    this.shopByQuery('Antiques');
    
    this.itemsService.bestDeals().then((response) => {
      this.bestDeals = <NewItem[]>response;
    });

    this.itemsService.newCollection().then((response) => {
      this.newArrivals = <NewItem[]>response;
    });

    this.itemsService.bestDeals('Antiques').then((response) => {
      this.mAntiques = <NewItem[]>response;
    });

    this.itemsService.newCollection('Antiques').then((response) => {
      this.gAntiques = <NewItem[]>response;
    });

    this.itemsService.bestDeals('Business, Office & Industrial').then((response) => {
      this.mBusiness = <NewItem[]>response;
    });

    this.itemsService.newCollection('Business, Office & Industrial').then((response) => {
      this.gBusiness = <NewItem[]>response;
    });    
  }

  shopByQuery(modality :  string) {
    this.itemsService.bestDeals(modality).then((response) => {
      this.shopByItems = <NewItem[]>response;
    });
  }
}
