import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewItem } from '../models/new-item';
import { SearchFilter } from '../models/search-filter';
import { AuthService } from '../services/auth.service';
import { ItemsService } from '../services/items.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  showFilter : boolean = false;
  searchResult : NewItem[] = [];
  searchTittle : string = '';
  searchFilter : SearchFilter = {
    selling : true,
    category : 'all',
    condition : 'all',
  }
  color: ThemePalette = 'primary';
  categories: string[] = [
    'Antiques',
    'Art',
    'Baby',
    'Books, Comics & Magazines',
    'Business, Office & Industrial',
    'Cameras & Photography',
    'Cars, Motorcycles & Vehicles',
    'Clothes, Shoes & Accessories',
    'Coins',
    'Collectables',
    'Computers/Tablets & Networking',
    'Crafts',
    'Dolls & Bears',
    'Events Tickets',
    'Everything Else',
    'Films & TV',
    'Garden & Patio',
    'Health & Beauty',
    'Holidays & Travel',
    'Home, Furniture & DIY',
    'Jewellery & Watches',
    'Mobile Phones & Communication',
    'Music',
    'Musical Instruments & DJ Equipment',
    'Pet Supplies',
    'Pottery, Ceramics & Glass',
    'Property',
    'Sound & Vision',
    'Sporting Goods',
    'Sports Memorabilia',
    'Stamps',
    'Toys & Games',
    'Vehicle Parts & Accessories',
    'Video Games & Consoles',
    'Wholesale & Job Lots',
  ];

  constructor(private itemsService: ItemsService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      (params: any) => {
          this.searchFilter.selling = params['selling'] ? params['selling'] == 'true' : this.searchFilter.selling;
          this.searchFilter.category  = params['category'] ? params['category'] : this.searchFilter.category;
          this.searchFilter.condition = params['condition'] ? params['condition'] : this.searchFilter.condition;
      }
    );

    this.search();
  }

  public search() {
    this.itemsService.itemsSearch(this.searchTittle, this.searchFilter).then((response) => {
      this.searchResult = <NewItem[]>response;
    }); 

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        selling: this.searchFilter.selling,
        category: this.searchFilter.category,
        condition: this.searchFilter.condition
      },
      queryParamsHandling: 'merge'
    });
  }

  public toggleFilter() {
    this.showFilter = !this.showFilter;
  }

}
