import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignUpComponent } from './sign-up/sign-up.component';
import { LoginComponent } from './login/login.component';
import { EmailComponent } from './email/email.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { ListItemComponent } from './list-item/list-item.component';
import { redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { AuthGuard } from "@angular/fire/auth-guard";
import { SearchComponent } from './search/search.component';
import { MyItemsComponent } from './my-items/my-items.component';
import { ItemPageComponent } from './item-page/item-page.component';
import { OffersPageComponent } from './offers-page/offers-page.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'items/loved', component: MyItemsComponent, canActivate: [AuthGuard ], data: { authGuardPipe: redirectUnauthorizedToLogin }   },
  { path: 'offers', component: OffersPageComponent, canActivate: [AuthGuard ], data: { authGuardPipe: redirectUnauthorizedToLogin }   },
  { path: 'items/search', component: SearchComponent },
  { path: 'login', component: LoginComponent },
  { path: 'email-login', component: EmailComponent },
  { path: 'signup', component: SignUpComponent },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard ], data: { authGuardPipe: redirectUnauthorizedToLogin }  },
  { path: 'items/list', component: ListItemComponent, canActivate: [AuthGuard ], data: { authGuardPipe: redirectUnauthorizedToLogin }  },
  { path: 'items/:id', component: ItemPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}