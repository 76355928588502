import { Component, OnInit } from '@angular/core';
import { NewItem } from '../models/new-item';
import { AuthService } from '../services/auth.service';
import { ItemsService } from '../services/items.service';

@Component({
  selector: 'app-my-items',
  templateUrl: './my-items.component.html',
  styleUrls: ['./my-items.component.css']
})
export class MyItemsComponent implements OnInit {

  myItems : NewItem[] = [];
  lovedItems : NewItem[] = [];

  constructor(private itemsService: ItemsService, private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.itemsService.getMyItems('all').then((response) => {
          this.myItems = <NewItem[]>response;
        }); 
      }
    });
    
  }

}
