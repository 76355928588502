<!--/.bg-holder-->

<div class="container">
  <div class="row flex-center">
    <a class="navbar-brand d-inline-flex" href="#">
      <img class="d-inline-block logo" src="assets/img/logo.png" alt="logo" />
      <span class="text-1000 fs-0 fw-bold ms-2 logo-text">Artiquary</span>
    </a>
    <div class="d-flex align-items-center flex-column">
      <p class="create-account-txt"> Hi, sign in or create an account. </p>
    </div>
    <div class="form-container">
      <h3>Sign Up</h3>
      <form #formData='ngForm' (ngSubmit)="onSubmit(formData)">
        <input type="text" placeholder="Email address" (ngModel)="email" name="email" class="input-txt" required>
        <input type="password" placeholder="Password" (ngModel)="password" name="password" class="input-txt" required>
        <button type="submit" [disabled]="!formData.valid" class="auth-btn">Create Account</button>
      </form>
      <a routerLink="/login" id="goback">Login</a>
    </div>
  </div>
</div>