import { Injectable } from '@angular/core';
import { User } from 'firebase/auth';
import { Firestore, doc, setDoc, Timestamp, collectionData, collection, query, where, getDocs, addDoc, QueryConstraint, getDoc, deleteDoc, orderBy } from '@angular/fire/firestore';
import { NewItem } from '../models/new-item';
import { Offer } from '../models/offer';
import { AuthService } from './auth.service';
import { ItemsService } from './items.service';

@Injectable({
  providedIn: 'root'
})
export class OffersService {
  user: User | undefined;

  constructor(private authService: AuthService, private firestore: Firestore, private itemsService: ItemsService) { 
    this.user = authService.userData;
  }

  public async makeOffer(item: NewItem, price: number) {
    const offer : Offer = new Offer; 
    offer.created = Timestamp.fromDate(new Date());
    offer.state = 'created';
    offer.emitterId = this.user!.uid;
    offer.emitterName = this.user!.displayName!;
    offer.itemId = item.id;
    offer.receiverId = item.user_id;
    offer.price = price;
    offer.thumbnailUrl = item.photos[0];
    offer.itemName = item.title;

    const docRef = await addDoc(collection(this.firestore, 'offers'), Object.assign({}, offer));

    return docRef.id;
  }

  public async getMyOffers() {
    let q; 
    let c = collection(this.firestore, 'offers')
    let qList : QueryConstraint[] = [];

    qList.push(where('emitterId', '==', this.user?.uid))
    q = query(c, ...qList)
    
    let querySnapshot = await getDocs(q);

    let items : any[] = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let item = <Offer>doc.data()
      item.id = doc.id;
      items.push(item)
    });

    q = query(c, where('receiverId', '==', this.user?.uid))
    
    querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let item = <Offer>doc.data()
      item.id = doc.id;
      items.push(item)
    });

    return items;
  }
}
