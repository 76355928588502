import { Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Auth, authState,  signOut, User, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword } from '@angular/fire/auth';
import { traceUntilFirst } from '@angular/fire/performance';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UsersService } from './users.service';

@Injectable()
export class AuthService {
  private readonly userDisposable: Subscription|undefined;
  public readonly user: Observable<User | null> = EMPTY;
  public userData: User | undefined = undefined;
  public currentToken : string | undefined;

  showLoginButton = false;
  showLogoutButton = false;

  constructor(
      @Optional() private auth: Auth,
      private router: Router,
      private userService: UsersService
    ) {
      if (auth) {
        this.user = authState(this.auth);
        this.userDisposable = authState(this.auth).pipe(
          traceUntilFirst('auth'),
          map(u => !!u)
        ).subscribe(isLoggedIn => {
          this.showLoginButton = !isLoggedIn;
          this.showLogoutButton = isLoggedIn;
        });

        this.auth.currentUser?.getIdToken(true).then(async token => {
          this.currentToken = token;
        });

        this.user.subscribe((user) => {
          if (user) {
            this.userData = user;
          }
        });
      }     
    }

  login(email: string, password: string) {
    signInWithEmailAndPassword(this.auth, email, password)
    .then(value => {
      this.router.navigateByUrl('/');
    })
    .catch(err => {
      console.log('Something went wrong: ', err.message);
    });
  }

  emailSignup(email: string, password: string) {
    createUserWithEmailAndPassword(this.auth, email, password)
    .then(value => {
     console.log('Sucess', value);
     this.userService.createUser(value.user).subscribe(response => {
      console.log(response);
    });
     this.router.navigateByUrl('/');
    })
    .catch(error => {
      console.log('Something went wrong: ', error);
    });
  }

  googleLogin() {
    return this.oAuthLogin()
      .then(value => {
     console.log('Sucess', value),
     this.userService.createUser(value.user).subscribe(response => {
      console.log(response);
    });
     this.router.navigateByUrl('/');
   })
    .catch(error => {
      console.log('Something went wrong: ', error);
    });
  }

  async logout() {
    return await signOut(this.auth);
  }

  getUserDetails() {
    return this.user;
  }

  private async oAuthLogin() {
    return await signInWithPopup(this.auth, new GoogleAuthProvider());
  }

  ngOnDestroy(): void {
    if (this.userDisposable) {
      this.userDisposable.unsubscribe();
    }
  }
}