import { Timestamp } from "firebase/firestore";
import { NewEvent } from "./new-event";

export class NewItem {
    public id : string | undefined;
    public likes: number = 0;
    public titleList: string[] = [];
    public user_id: string | undefined;
    public events: NewEvent[] = [];

    constructor(
      public title: string,
      public subtitle: string,
      public selling: boolean,
      public price: number | null,
      public visible: boolean,
      public category: string,
      public condition: string,
      public conditionDesc: string,
      public description: string,
      public culture: string,
      public brand: string,
      public date: Timestamp | undefined,
      public material: string[],
      public firstLineAddress: string | undefined,
      public postcode: string,
      public city: string,
      public country: string,
      public photos: any[]
    ) {}
  }
