import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Firestore, doc, Timestamp, collection, query, where, getDocs, addDoc, QueryConstraint, getDoc, deleteDoc, orderBy } from '@angular/fire/firestore';
import { getStorage, ref, uploadString, deleteObject, getDownloadURL } from "firebase/storage";
import { AuthService } from './auth.service';
import { User } from 'firebase/auth';
import { NewItem } from '../models/new-item';
import { ItemsList } from '../home/home.component';
import { SearchFilter } from '../models/search-filter';
import { NewEvent } from '../models/new-event';
@Injectable({
  providedIn: 'root'
})
export class ItemsService {
  private url = environment.apiURL + '/api/v1/items';
  public user: User | undefined;

  constructor(private authService: AuthService, private httpClient: HttpClient, private firestore: Firestore) {
    authService.user.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
   }
   

  public async bestDeals(_category: string = 'all'){
    let q;

    if (_category != 'all') {
      q = query(
        collection(this.firestore, 'items'),
        where('visible', '==', true),
        where('selling', '==', true),
        where('category', '==', _category)
      );
    } else {
      q = query(
        collection(this.firestore, 'items'),
        where('visible', '==', true),
        where('selling', '==', true)
      );
    }
    
    const querySnapshot = await getDocs(q);
    let items : any[] = [];
    querySnapshot.forEach((doc) => {
      let item = <NewItem>doc.data()
      item.id = doc.id;
      items.push(item)
    });

    return items;
  }

  public async newCollection(_category: string = 'all'){
    let q;

    if (_category != 'all') {
      q = query(
        collection(this.firestore, 'items'),
        where('visible', '==', true),
        where('selling', '==', false),
        where('category', '==', _category)
      );
    } else {
      q = query(
        collection(this.firestore, 'items'),
        where('visible', '==', true),
        where('selling', '==', false)
      );
    }
    
    const querySnapshot = await getDocs(q);
    let items : any[] = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let item = <NewItem>doc.data()
      item.id = doc.id;
      items.push(item)
    });

    return items;
  }

  public async createItemFirebase(itemForm: NewItem){
    let docData = <any>itemForm
    docData["user_id"] = this.user?.uid;
    docData["last_update"] = Timestamp.fromDate(new Date());
    docData["created"] = Timestamp.fromDate(new Date());
    docData["likes"] = 0;

    const docRef = await addDoc(collection(this.firestore, 'items'),  Object.assign({}, docData));
    
    return docRef.id;
  }
  
  public async removeItem(item : NewItem) {
    if (!item.id) {
      return
    }

    const docRef = doc(this.firestore, "items", item.id);   
    this.removeItemImages(item.id, item.photos)
  
    await deleteDoc(docRef);
  }

  public createItem(itemForm: NewItem){
    var formData: any = new FormData();
    formData.append("title", itemForm.title);
    formData.append("subtitle", itemForm.subtitle);
    formData.append("selling", itemForm.selling);
    formData.append("price", itemForm.price);
    formData.append("visible", itemForm.visible);
    formData.append("category", itemForm.category);
    formData.append("condition", itemForm.condition);
    formData.append("conditionDesc", itemForm.conditionDesc);
    formData.append("description", itemForm.description);
    formData.append("culture", itemForm.culture);
    formData.append("brand", itemForm.brand);
    formData.append("date", itemForm.date);
    formData.append("material", itemForm.material);
    formData.append("firstLineAddress", itemForm.firstLineAddress);
    formData.append("postcode", itemForm.postcode);
    formData.append("city", itemForm.city);
    formData.append("country", itemForm.country);
    formData.append("photos", itemForm.photos);

    return this.httpClient.post(this.url, formData);
  }

  public async uploadItemImages(itemId : string, images : string[]) {
    const storage = getStorage();
    let numberImages : number  = 0;

    for (let image of images) {
      let storageRef = ref(storage, `items/${this.user?.uid}/${itemId}/${numberImages}`);
      await uploadString(storageRef, image, 'data_url').then((snapshot) => {
        console.log('Uploaded a base64url string!');
      });

      numberImages++;
    }
  }

  public async removeItemImages(itemId : string, images : string[]) {
    const storage = getStorage();
    let numberImages : number  = 0;

    for (let image of images) {
      let storageRef = ref(storage, `items/${this.user?.uid}/${itemId}/${numberImages}`);
      await deleteObject(storageRef).then((snapshot) => {
        console.log('Removed image!');
      });

      numberImages++;
    }
  }

  public getImages(itemId : string) {
    return this.httpClient.get(`${this.url}/${itemId}/photos`);
  }

  public async getThumbnail(itemId : string) {
    const storage = getStorage();

    let storageRef = ref(storage, `items/${this.user?.uid}/${itemId}/0`);

    return getDownloadURL(storageRef)
  }  

  public async itemsSearch(title: string, filterForm : SearchFilter){
    let q

    let c = collection(this.firestore, 'items')
    let qList : QueryConstraint[] = [];

    if (title != '') {
      qList.push(where('titleList', 'array-contains-any', title.toLocaleLowerCase().split(' ')))
    }

    qList.push(where('visible', '==', true))
    
    if (filterForm.category != 'all') {
      qList.push(where('category', '==', filterForm.category))
    } 

    if (filterForm.condition != 'all') {
      qList.push(where('condition', '==', filterForm.condition))
    } 

    qList.push(where('selling', '==', filterForm.selling))

    q = query(collection(this.firestore, 'items'), ...qList)
    
    const querySnapshot = await getDocs(q);
    
    let items : any[] = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let item = <NewItem>doc.data()
      item.id = doc.id;
      items.push(item)
    });

    return items;
  }

  public async getMyItems(_category: string = 'all'){
    let q;
    let c = collection(this.firestore, 'items')
    let qList : QueryConstraint[] = [];

    qList.push(where('user_id', '==', this.user?.uid))
    
    if (_category != 'all') {
      qList.push(where('category', '==', _category))
    } 

    q = query(collection(this.firestore, 'items'), ...qList)
    
    const querySnapshot = await getDocs(q);
    let items : any[] = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let item = <NewItem>doc.data()
      item.id = doc.id;
      items.push(item)
    });

    return items;
  }

  public async getItem(itemId: string, _public : boolean = false){
    const docRef = doc(this.firestore, "items", itemId);
    const docSnap = await getDoc(docRef);

    return docSnap.data();
  }

  public async createEvent(itemId: string, eventForm: NewEvent){
    const itemRef = doc(this.firestore, "items", itemId);
    let docData = <any>eventForm

    const docRef = await addDoc(collection(itemRef, 'events'),  Object.assign({}, docData));
    
    return (await getDoc(docRef)).data();
  }

  public async getEvents(itemId: string){
    const docRef = doc(this.firestore, "items", itemId);
    const events = collection(docRef, "events");
    
    let q = query(events, orderBy("date", "desc"))

    const querySnapshot = await getDocs(q);
    let items : any[] = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let item = <NewEvent>doc.data()
      item.id = doc.id;
      items.push(item)
    });

    return items;
  }

    
  public async removeEvent(item : NewItem, eventId : string) {
    if (!eventId && !item.id) {
      return
    }

    const docRef = doc(this.firestore, "items", item.id!.toString());
    const eventRef = doc(docRef, "events", eventId!.toString());   
  
    await deleteDoc(eventRef);
  }
}
